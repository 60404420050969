/*Dora Custom CSS*/
#prod-search-btn, .dropdown-toggle.pf-filter-item, .mid-footer, .button { background-color: #8e8589; }
.nav-link.mid { border-color: #8e8589; color: #8e8589; }
.social-link:hover { color: #68474b; }
.pf-promotion-container { width: 100%; }
.mega-list.w--open { border: 1px solid #8e8589; }
.megaheading, .nav-container .heading, .tag, a.categorylink { border-color: #8e8589!important; color: #8e8589 !important; }
.pf-btn.pf-btn-primary, .w-tab-menu .pf-tab-header.w--current, .pf-btn.pf-btn-colour-one, #pfQoSearchBtn.pf-btn.pf-btn-colour-one:hover, .slider-container a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
border: 1px solid #8e8589 !important; background: #8e8589 !important; }
.pf-btn.pf-btn-primary:hover { color: #68474b !important; border-color: #68474b !important; }
.pf-terms-wrapper a { color: #8e8589; }
.w--current, .nav-link.mid:hover, .contact-text a:hover, a.pf-normal-link:hover, a:hover, #filter .cat-selected, .pf-filter-item a:hover { color: #68474b !important; }
.pf-input.pf-hover-colour-one:focus, .pf-input.pf-hover-colour-one:hover, .pf-input.pf-hover-colour-two:focus, .pf-input.pf-hover-colour-two:hover { border-color: #68474b; }
.ctabtn-wrapper a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) { background-color:#68474b; }
.tag:hover, .slider-container a:not(.mbss-btn-icon):not(.re-icon):not(.re-button):hover { background-color: #68474b!important; color: #fff !important; }
.button:hover, .button a:hover { background-color: #68474b; color: #fff!important; border-color: #68474b; }

/* Dora custom - CSS updated*/

@font-face {
	font-family: "CenturyGothic";
	src: url("/Assets/release/site/css/fonts/century-gothic/bd45538f-4200-4946-b177-02de8337032d.eot?#iefix");
	src: url("/Assets/release/site/css/fonts/century-gothic/bd45538f-4200-4946-b177-02de8337032d.eot?#iefix") format("eot"), url("/Assets/release/site/css/fonts/century-gothic/700cfd4c-3384-4654-abe1-aa1a6e8058e4.woff2") format("woff2"), url("/Assets/release/site/css/fonts/century-gothic/9908cdad-7524-4206-819e-4f345a666324.woff") format("woff"), url("/Assets/release/site/css/fonts/century-gothic/b710c26a-f1ae-4fb8-a9fe-570fd829cbf1.ttf") format("truetype"), url("/Assets/release/site/css/fonts/century-gothic/c9e387df-c0d3-42e1-8de6-78c41f68594c.svg#c9e387df-c0d3-42e1-8de6-78c41f68594c") format("svg");
	font-weight: normal;
}

@font-face {
	font-family: "CenturyGothic";
	src: url("/Assets/release/site/css/fonts/century-gothic/38471ac0-7849-4a39-9f97-f89d78f42142.eot?#iefix");
	src: url("/Assets/release/site/css/fonts/century-gothic/38471ac0-7849-4a39-9f97-f89d78f42142.eot?#iefix") format("eot"), url("/Assets/release/site/css/fonts/century-gothic/79803af7-369c-4a5f-bc95-fb69ee7e77f8.woff2") format("woff2"), url("/Assets/release/site/css/fonts/century-gothic/483a662e-88d8-4865-8dd3-1515c67fe28b.woff") format("woff"), url("/Assets/release/site/css/fonts/century-gothic/d29e72e3-fcd5-4fa6-8cfb-986a2e33e105.ttf") format("truetype"), url("/Assets/release/site/css/fonts/century-gothic/1e08e1b1-b459-4819-95bc-54667cb4d9b5.svg#1e08e1b1-b459-4819-95bc-54667cb4d9b5") format("svg");
	font-style: italic;
	font-weight: normal;
	}

@font-face {
	font-family: "CenturyGothic";
	src: url("/Assets/release/site/css/fonts/century-gothic/b247e158-e647-4a3d-9637-944de5124043.eot?#iefix");
	src: url("/Assets/release/site/css/fonts/century-gothic/b247e158-e647-4a3d-9637-944de5124043.eot?#iefix") format("eot"), url("/Assets/release/site/css/fonts/century-gothic/bc0668e2-e5c8-45ce-954d-3b998c6b6803.woff2") format("woff2"), url("/Assets/release/site/css/fonts/century-gothic/2ef5ecda-1772-45e2-843f-a13f5d76ed3a.woff") format("woff"), url("/Assets/release/site/css/fonts/century-gothic/33cf10ca-a6bb-4888-a320-d38720c9847b.ttf") format("truetype"), url("/Assets/release/site/css/fonts/century-gothic/3428a0e6-cb4d-408a-8331-cc78554ab49c.svg#3428a0e6-cb4d-408a-8331-cc78554ab49c") format("svg");
	font-weight: bold;
}

body { font-family: "CenturyGothic", "Helvetica Neue", Arial, sans-serif !important; }
.pf-image-collection a:hover, .flex-parent h3 a:hover, .mbss-content h3 a:hover { color: #ccc !important; }

.brand { padding-top: 14px; }
.nav-link { padding: 11px 0px 10px 20px; }
.w-dropdown-toggle { padding: 1px 0px 10px; }
.shrink .nav-link { padding-top: 10px; padding-bottom: 8px; }
.nav .mega-list .mega-col > .heading { padding-bottom: 10px; }

.pf-image-collection { margin-top: 0.8%; }
.pf-image-collection h3 { margin-bottom: 10px; }
.pf-image-collection a { position: relative !important; display: inline-block; width: auto; }
.image-height-420 { height: 420px; }
.section.top-categories .title-wrapper h2 { padding-top: 0px; }

.pf-promotion-strip-enabled .ecs-main-container-nbp { padding-top: 177px !important; }
.mega-list.w--open { padding-top: 8px; padding-bottom: 5px; }
.mega-list.w-dropdown-list { left: auto; transform: none; }

.nav-link.nav-dropdown:hover { color: #68474b; }
.nav-link.nav-dropdown.w--open { color: #68474b; }

.nav-container .heading { text-transform: none; }

#prod-search-btn, .button, .dropdown-toggle.pf-filter-item, .mid-footer { background-color: #68474b; }

#bag-opener .fa-shopping-cart { color: #68474b !important; }
.pf-promotion-container { background-color: #68474b !important; }
.pf-promotion-container span { color: #fff !important; }

#scrollUp:hover { color: #fff !important; opacity: 1; }

.list-view-add-cart-wrapper .pf-btn.pf-btn-primary, .pf-detail-view-add-cart-wrapper .pf-btn.pf-btn-primary { border-left: 0 !important; }
#pf-product-details-page .pf-product-image-thumb.w--current { border: 1px solid #68474b; }
.lg-outer .lg-thumb-item.active, .lg-outer .lg-thumb-item:hover { border-color: #68474b !important; }

.ui-widget-content { background: #fff; color: #8D8D8D !important; }
.ui-menu { padding: 5px; }
.ui-menu-item.ui-state-focus { color: #68474b !important; font-weight: 500 !important; }

.section.title-wrapper { padding-top: 0px; margin-top: 0px; margin-bottom: 0px; padding-left: 20px; padding-right: 20px; }

.mid-footer .ecs-column-2:first-of-type { margin-right: 10.5%; }
.mid-footer .ecs-column-2:last-of-type { margin-right: 0%; }

.ecs-column-2.footer-links:last-of-type { padding-right: 0%; }

.reqeust-credit-address-wrapper{
    overflow: auto;
    clear: both;
    padding-bottom: 30px;
}

.information-page-wrapper .page-title.section.title-wrapper {
	color: #68474b;
	font-size: 30px;
	margin-top: 30px;
}
.wishlist-main-buttons .create-wish-List-btn {
    margin-bottom: 5px;
}

#wishlistCheckboxName span{
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
    text-align: left;
}

.post-edit-wishlist-btn, .cancel-edit-wishlist-name-btn {
    padding-top: 0;
}

.pf-wishlist-edit-name:not(.hidden) + #WishlistDropdown{
    padding-top: 17px;
}

.information-page-nav {
    margin-bottom: 30px;
}
    .information-page-nav p {
        margin-bottom: 0;
        padding: 0;
    }

.information-page-nav-header {
    padding: 10px;
    margin-bottom: 0;
    background-color: #68474b;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    text-transform: uppercase;
    color: #fff;
    font-size: 15px;
}

.information-page-nav-item {
	position: relative;
	display: block;
	padding: 0.75rem 1.25rem;
	margin-bottom: -1px;
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.125);
}
	.information-page-nav-item a {
		color: #707070;
        text-decoration: none;
	}
        .information-page-nav-item a:hover {
            color: #333;
        }
    .information-page-nav-item a.move-btn-list {
        position: absolute;
        left: 3px;
    }

.information-page-content {
	margin-right: 0;
    text-align: left;
}
    .information-page-content a {
        color: #333;
        text-decoration: underline;
    }
        .information-page-content a:hover {
            text-decoration: none;
        }

    .information-page-content h3 {
        margin-bottom: 10px;
    }

    .information-page-content table th, .information-page-content table td {
        padding: 7px !important;
        border: 1px solid #aeabad;
    }

    .information-page-content table th {
        background: #aeabad;
        color: #fff;
        font-weight: 500;
    }
.blogpost-wrapper .blog-img-large {
    min-height: 250px !important;
}
#tradeLoginLinkToRetail {
    color: black;
    text-decoration: underline;
    text-transform: uppercase;
}

.horizontal-spacer {
    margin-bottom: 30px;
}

.trade-show img {
    height: auto;
}
.AboutList .section:nth-child(2n+1) .col-right-space {
    float: right;
}
.AboutList .addListItem2Btn {
    position:relative;
    float:right;
}
.pf-all-wishlists .dropdown-toggle.pf-filter-item {
    color: #68474b;
}
.request-a-catalogue-page .ecs-row-container {
    padding-bottom: 120px!important;
}
.apply-for-credit-page .ecs-row-container {
    padding-bottom: 150px!important;
}
input.pf-multi-select-item+label:after {
    border: 1px solid #68474b;
    color: #68474b;
}
input.pf-multi-select-item:checked+label:after {
    background-color: #68474b;
    color: #fff;
}
a.pf-stock-notify-me-btn {
    color: #68474b;
}
.request-a-catalogue-page .section.ecs-row-container {
    padding-bottom:100px;
}
input[type=text].pf-create-wish-list, input[type=text].pf-edit-wishlist {
    font-size: 1em !important;
    padding: 0 5px 0 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 34px;
}
.post-edit-wishlist-btn, .cancel-edit-wishlist-name-btn {
    padding-top:5px;
}
#checkout-form #checkout h3.underlined.text-uppercase.check-h3 {
    padding-top: 24px;
}
#pf-popup-cart-wrapper .pf-popup-cart-loader .cart-table img {
    min-width: 75px;
}
#pf-address-auto-btn, #pf-address-manual-btn {
    display: inline-block;
    margin-bottom: 10px;
}

.pf-btn.postcode-lookup-btn {
    margin-bottom: 10px;
}
/* media query for phones just before the iPad break point at 978px - start */
@media screen and (max-width:1024px) {
    .ecs-main-container {
        padding-top: 232px;
    }

    .mega-list.w-dropdown-list {
        left: 0;
    }
}
/* media query for phones just before the iPad break point at 978px - end */

/* media query for phones just before the iPad break point at 768px - start */
@media screen and (max-width:767px) {
    .information-page-content {
        margin-top: 20px;
        text-align: left;
	}
	.brand { padding-top: 10px; padding-bottom: 14px; }
	.mobile-search-icon .fa-search { color: #68474b; }
	.menu-button { color: #68474b; }
	#sidenav.sidenav nav .heading { font-size: 13px !important }
    .ecs-nav-group { padding-bottom: 0; }

	.pf-promotion-strip-enabled .ecs-main-container-nbp { padding-top: 134px !important; }
	.mobile-search-icon .fa-search { margin-top: 25px; }
	.menu-button { margin-top: 20px; }
	#bag-opener { right: -50px !important; }
}
/* media query for phones just before the iPad break point at 768px - end */

@media screen and (max-width:1199px) {
    .pf-checkout-cart-table .pf-checkout-cart-footer-row th:first-of-type {
        display: none;
    }
}